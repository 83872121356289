h1 {
    color:blue;
}

/* knowledge center styles */

.ch-article .ch-article-back span {
    color: #006fcc;
    font-weight: bold;
    font-size: 1.25em;
}

.ch-article .tech-doc-xml
{
    /* targets all content coming from TechDocs */
    font-size: 12px;
}

.ch-article .tech-doc-codeblock,
.ch-article .tech-doc-screen
{
    font-family: "Courier New", monospace;
    font-weight: 600;
    font-size: 12px;

}

.ch-article .tech-doc-codeblock
{
    font-size:12px;
    font-weight:600;
    padding: 0;
  white-space: pre-wrap;
  padding: 20px;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  border: none;
  border-radius: 25px;
  background: #F8F8F8;

}
.ch-article pre.tech-doc-screen {
    margin:10px 0;
}

.ch-article .tech-doc-thead
{
    padding: 15px 8px;
    background: rgb(25, 34, 46);
    color: white;
    font-weight: 100;
    text-transform: uppercase;
}
.ch-article .tech-doc-thead .tech-doc-entry
{
  padding:3px;
}

.ch-article .tech-doc-thead th.tech-doc-entry div.tech-doc-p div
{
    display: block !important;
    padding: 8px 8px 0px 8px;
}

.ch-article span.tech-doc-userinput,
.ch-article span.tech-doc-systemoutput
{
    font-family: "Courier New", monospace;
    color: #000;
    font-weight: 600;
    font-size: 12px;
}

.ch-article pre
{
  padding: 0;
    white-space: pre-wrap;
    padding: 20px;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    border: none;
    border-radius: 25px;
    background: #F8F8F8;
}
.ch-article span.tech-doc-userinput > div
{
    padding-right:3px;
}

.ch-article ol.tech-doc-steps
{
    counter-reset: procedure-step;
    margin: 10px 0;
    list-style: none;
    padding-right: 0;
}

.ch-article ol.tech-doc-steps li.tech-doc-step
{
    margin: 15px 0;
}

.ch-article li.tech-doc-li::marker,
.ch-article li::marker
{
    color: rgb(29, 184, 70);
    font-weight: 500;
    font-size: 1rem;
}
.ch-article .tech-doc-substep
{
    margin:10px 0;
}

.ch-article .tech-doc-uicontrol
{
    font-weight: bold;
}
.ch-article .tech-doc-entry
{
    padding: 5px 5px 5px 0;
    border-bottom:1px solid #eaeaea;

}
.ch-article .tech-doc-entry .tech-doc-p
{
    line-height: normal;
}
.ch-article .tech-doc-p,
.ch-article .tech-doc-shortdesc
{
  margin:10px 0;
  line-height:140%;
}
.ch-article .tech-doc-title,
.ch-article h2
{
  font-size:1.2em;
  margin:30px 0 15px 0;
  color:black;
  padding-bottom:5px;
  border-bottom: 1px solid #eaeaea;
}
.ch-article .tech-doc-menucascade .tech-doc-uicontrol::after
{
  content: " > ";
}

.ch-article .tech-doc-menucascade .tech-doc-uicontrol:last-child::after
{
  content: none;
}
.ch-article .tech-doc-note
{
      margin: 10px 0;
    border: 1px solid rgb(25, 34, 46);
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    position: relative;
    display: flex;
    border-radius: 15px;
}
.ch-article .tech-doc-note::before
{
      background-color: rgb(25, 34, 46);
    font-family: 'simple-line-icons';
    content: url(https://docs.paloaltonetworks.com/content/dam/techdocs/en_US/images/icons/css/note-icon.png);
    color: #fff;
    font-size: 300%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
    min-height: 100%;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
}
.ch-article .tech-doc-note.tip::before
{
  font-family: 'Material Icons';
    content: '\e90f';
}
.ch-article .tech-doc-note .tech-doc-p
{
  margin:8px;
}
.ch-article
{
  counter-reset: step-counter;
}
.ch-article .tech-doc-steps .tech-doc-step
{
  padding:10px 0;
}
.ch-article .tech-doc-steps .tech-doc-step::before
{
  counter-increment: step-counter;
  content: "STEP  " counter(step-counter) " : ";
  color: rgb(29, 184, 70);
    font-weight: 800;
    font-size:1.3em;
}
.ch-article .tech-doc-steps .tech-doc-step .tech-doc-cmd
{
  font-weight:600;
   font-size:1.3em;
}
.ch-article .tech-doc-steps .tech-doc-step .tech-doc-substep .tech-doc-cmd
{
  font-weight:normal;
  font-size:1em;
}
.ch-article [data-outputclass="checkbox"] li
 {
    margin: 10px 0 10px 0;
    list-style-position: outside;
    padding-left: 10px !important;
    padding-top:-9px !important;
    display: list-item;
    list-style-type: none;
    position: relative;
 }
.ch-article [data-outputclass="checkbox"] li::before {
    /* color: #3f9b6f !important; */
    content: url(https://docs.paloaltonetworks.com/content/dam/techdocs/en_US/images/icons/help/checkbox-icon-green.png) !important;
    position: absolute;
    left: -10px !important;
    right: 100%;
}
.ch-article .tech-doc-term
{
  cursor: not-allowed;
  text-decoration: none;
  color:black;
  font-weight:bold;
}