.customPanwdsCard {
    margin: auto;
    display: flex;
    padding: 27px 32px;
    overflow: visible;
    min-width: 450px;
    background: #F9F9F9;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 25%);
    min-height: 450px;
    border-radius: 5px;
    flex-direction: column;
}

.actions > Button {
    float: right;
    margin-right: 15px;
}
