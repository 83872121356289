div.react-table-container > div div[role="row"] .tw-flex > div {
    border-right-width: 1px;
}

div.react-table-container > div div[role="rowgroup"]:nth-child(1) div[role="row"] {
    height: 2.75rem;
}

div.react-table-container > div div[role="rowgroup"]:nth-child(1) div[role="row"] .tw-flex > div {
    height: auto;
}

div.react-table-container > div div[role="row"] .tw-flex:last-child > div:last-child {
    border-right-width: 0;
}

div.react-table-container > div div[role="rowgroup"]:nth-child(2) div[role="row"] .tw-flex.tw-flex-grow > div {
    border-left: 1px solid #e4e3e2;
    border-bottom: 1px solid #e4e3e2;
}

div.react-table-no-infinite > div div[role="rowgroup"]:nth-child(1) div[role="row"] > .tw-flex.tw-flex-grow > div:not(:nth-last-child(2)) {
    border-left: none;
    border-right: 1px solid #e4e3e2;
}

div.react-table-no-infinite > div div[role="rowgroup"]:nth-child(1) div[role="row"] > div:not(.tw-flex-grow) > div {
    border-right: 1px solid #e4e3e2;
}


div.react-table-container > div div[role="rowgroup"]:nth-child(2) div[role="row"] > div:not(.tw-flex-grow) > div {
    border-left: none;
    border-right: 1px solid #e4e3e2;
    border-bottom: 1px solid #e4e3e2;
}

div.react-table-container > div div[role="rowgroup"]:nth-child(2) div[role="row"]:last-child .tw-flex.tw-flex-grow > div {
    border-bottom: none;
}

div.react-table-container > div div[role="rowgroup"]:nth-child(2) div[role="row"]:last-child > div:not(.tw-flex-grow) > div {
    border-bottom: none;
}

div.react-table-container > div div[role="rowgroup"]:nth-child(2) div[role="row"] .tw-flex.tw-flex-grow > div:first-child {
    border-left: none;
}

div.row-selection.react-table-container > div div[role="rowgroup"]:nth-child(1) div[role="row"] > div:not(.tw-flex-grow) > div {
    margin-left: 1px;
}

div.row-selection.react-table-container > div div[role="rowgroup"]:nth-child(1) div[role="row"] > .tw-flex.tw-flex-grow > div:first-child {
    margin-left: 1px;
}

div.no-row-selection.react-table-container > div div[role="rowgroup"]:nth-child(1) div[role="row"] > .tw-flex.tw-flex-grow > div:first-child {
    margin-left: 2px;
}

div.row-selection.single-selection.react-table-container > div div[role="rowgroup"]:nth-child(2) div[role="row"] > div:not(.tw-flex-grow) > div > div {
    padding-left: 0.7rem;
}

.applications-categorized-table > div div[role="rowgroup"]:nth-child(2) {
    height: 126px;
    overflow-y: scroll;
    overflow-x: hidden;
}

div[data-testid="add-applications-modal"] div[data-testid="card"] {
    max-height: 100vh !important;
}

div[data-testid="add-applications-modalBody"] {
    padding: 0;
}

div[data-testid="add-applications-modalHeader"] {
    background-color: rgba(249, 249, 249, 1);

}

div[data-testid="add-applications-modalFooter"] {
    background-color: rgba(249, 249, 249, 1);
}

.applications-selected-table > div {
    width: 488px;
}

.applications-selected-table > div div[role="rowgroup"]:nth-child(2) {
    height: 123px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.applications-dependsOn-table > div {
    width: 488px;
}

.applications-dependsOn-table > div div[role="rowgroup"]:nth-child(2) {
    height: 123px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.vpc-group-edit-table > div div[role="rowgroup"]:nth-child(2) {
    height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.vpc-delete-table > div div[role="rowgroup"]:nth-child(2) {
    height: 240px;
    overflow-y: scroll;
    overflow-x: hidden;
}

div.react-table-container > div > div.tw-px-4:first-child {
    padding-top: 15px;
    padding-bottom: 15px;
}

div.react-table-container > div > div.tw-px-4:first-child > div.tw-flex-grow:first-child > div:not(.tw-font-lato){
    display: none;
}

div.react-table-container > div div[role="rowgroup"]:nth-child(1) div[role="row"].tw-group .group-hover\:tw-col-header-border-r-gray-300 {
    display: none;
}

/* div.react-table-container > div > div:last-child > div.tw-flex > div:last-child {
     display: none;
} */

.noDataStyles div[role="rowgroup"]:nth-child(2) {
    display: none;
    /* height: 200px !important; */
}

.styleFiletrsCointainer div.tw-p-1 {
    width: 75%;
}

.tagsIP-table > div div[role="rowgroup"]:nth-child(2) {
    height: 460px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.tags-IPs-table > div div[role="rowgroup"]:nth-child(2) {
    height: 472px;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* div.overflow-table-container > div > div.tw-px-4:first-child > div.tw-flex-grow:first-child{
    width: 50%;
} */

/* div.overflow-table-container > div div.tw-flex-grow.tw-overflow-auto{
    height: calc(100vh - 160px);
} */
